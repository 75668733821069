import AppBar from '../appBar/AppBar'
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <>
        <AppBar/>
        <Outlet/>
    </>
  )
}

export default Layout