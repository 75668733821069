import React, { useEffect } from 'react'
import Header from '../../../components/auth/header/Header'
import { useNavigate } from 'react-router-dom'
import Signup from '../../../components/auth/singup/singup/Singup'
import Verification from '../../../components/auth/verify/Verification'
import AuthImage from '../../../components/auth/nestedComponents/authImage/AuthImage'
import toast from 'react-hot-toast'
import { useAppDispatch, useAppSelector } from '../../../RTK/hooks/hooks'
import { sendSignupRequest, verifySignupRequest } from '../../../RTK/features/verifySignupSlice/verifySignupSlice'

function SignupLayout() {
  const [page, setPage] = React.useState<number>(1)
  const [email, setEmail] = React.useState('')
  const navigate = useNavigate()

  const {sendError, loading, verifyError} = useAppSelector((state) => state.verifySignup)
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if(sendError)
      toast.error(sendError, {duration: 3000});
  },[sendError])

  async function requestOTP(){
    try{
      const result = await dispatch(sendSignupRequest(email)).unwrap()
      toast.success('Check your email for the code', {duration: 3000});
    } catch {}
  }

  async function handleAction(code:string){
    try{
      const result = await dispatch(verifySignupRequest({email, code})).unwrap()
      toast.success('Your email has been registered', {duration: 3000})
      setPage(0);
    }
    catch{}
  }

  function diplayedPage() {
    switch (page) {
      case 1: return <Signup setPage={setPage} setEmail={setEmail} email={email} />
      case 2: return <Verification setPage={setPage} email={email} requestOTP={requestOTP} handleAction={handleAction} error={verifyError} loading={loading}/>
      default: return null
    }
  }

  useEffect(() => {
    if (page == 0) {
      navigate('/', { replace: true })
    }
  }, [page, navigate])

  return (
    <div className='authLayout'>
      <Header setPage={setPage} show={page == 1} gray/>
      <div className={`authWra flex-row-reverse ${page!=1 && 'authWraBg'}`}>
        <div className={`${page == 1 && 'basis '}pageWra`}>
          {diplayedPage()}
        </div>
        <div className={`${page == 1 ? 'rightImage -scale-x-100' : 'hidden'}`}>
          <AuthImage/>
        </div>
      </div>
    </div>
  )
}

export default SignupLayout