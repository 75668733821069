import React, { useState, useRef, useEffect } from 'react';
import { VerifacationProps } from '../../../models/auth/auth';
import Timer from '../nestedComponents/timer/Timer';
import CustomButton from '../nestedComponents/customButton/CustomButton';
import { validateOTP } from '../../../utils/validation';
import './verification.css';
import toast from 'react-hot-toast';

export default function Verification(props:VerifacationProps) {
  const {email, setPage, requestOTP, handleAction, loading, error} = props;
  const [OTP, setOTP] = useState(Array(6).fill(""));
  const [disabled, setDisabled] = useState(true)
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(()=>{
    if(error)
      toast.error(error, {duration: 3000});
  },[error])
  

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return;
    const newOTP = [...OTP];
    newOTP[index] = value;
    setOTP(newOTP);
    setDisabled(!validateOTP(newOTP))
    if (value && index < 5) inputRefs.current[index + 1]?.focus();
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !OTP[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  function handleVerificationAction(){
    const code: string = OTP.reduce((acc, curr) => acc + curr, '');
    handleAction(code)
  }

  useEffect(()=>{
    if(!email)
        setPage(1)
    requestOTP()
  },[])

  return (
    <div className='paper'>
        <div className='page'>
          <div className='verification-title'>
              <p>We sent a verification code to</p>
              <p>{email}</p>
          </div>
          <div className='form h-[310px]'>
            <div>
              <div className="verification-inputs">
                {OTP.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    inputMode="numeric"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="verification-input"
                  />
                ))}
              </div>
              <div className='timerWra'>
                <p>Resend code in <Timer requestOTP={requestOTP}/></p>
              </div>
            </div>
            <CustomButton disabled={disabled || loading} content='verify' action={()=>handleVerificationAction()}/>
          </div>
      </div>
    </div>
  );
}
