import React from 'react';
import Logo from '../../sharedComponents/logo/Logo';
import backArrow from '../../../assets/pics/backArrow.svg';
import './header.css'

function Header({ setPage, show = false, gray = false }: { setPage: React.Dispatch<React.SetStateAction<number>>, show?: boolean, gray?: boolean }) {
  return (
    <div className={`text-dodger-blue ${gray && 'lg:text-white'} header`}>
      <button type="button" className='back' onClick={() => setPage((pre) => pre - 1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" ><path fillRule="evenodd" d="M3.125 12.003c0-.525.268-.919.561-1.226.273-.286.702-.624 1.139-.968l1.014-.799L7.72 7.636c.52-.302 1.16-.547 1.823-.253.681.302.903.954.994 1.541.083.537.088 1.245.088 2.077h9.25a1 1 0 1 1 0 2h-9.25l-.088 2.081c-.09.587-.312 1.239-.994 1.541-.663.294-1.303.048-1.823-.254s-1.139-.79-1.881-1.375l-1.014-.799-1.139-.968c-.293-.307-.561-.699-.561-1.224z" fill="currentColor"/></svg>
        <p>Go Back</p>
      </button>
      <div className='logo-wra'>
          {show && <Logo />}
      </div>
    </div>
  );
}

export default Header;
