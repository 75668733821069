import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://elegance-hub.octopus-software.dev/api/',
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  }
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    config.headers['Accept-Language'] = 'en';

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;