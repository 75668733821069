import logo from '../../../assets/pics/cart.svg'
import './logo.css'
function Logo() {
  return (
    <div className='logo-con'>
        <h2>Beauty Clinic</h2>
    </div>
  )
}

export default Logo