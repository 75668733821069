import { useEffect, useState } from 'react'
import { CitySelectProps, OptionType } from '../../../../models/auth/auth'
import CustomizableSelect from '../customizableSelect/CustomizableSelect'
import { fetchCities } from '../../../../services/auth/signupService'
import city from '../../../../assets/pics/info.svg'


function CitySelect(props: CitySelectProps) {
    const {handleChange, formData, error, setError} = props
    const [cities, setCities] = useState<OptionType[]>([])

    useEffect(()=>{
        async function starter(){
            const res = await fetchCities()
            setCities(res)
        }
        starter()
    }, [])

  return (
    <CustomizableSelect options={cities} handleChange={handleChange} formData={formData} name='City' icon={city} error={error} setError={setError}/>
  )
}

export default CitySelect