import Layout from './components/sharedComponents/layout/Layout';
import LoginLayout from './pages/auth/login/LoginLayout';
import SignupLayout from './pages/auth/signup/SignupLayout';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from './RTK/app/store'
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
    <Toaster/>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/login" element={<LoginLayout/>}/>
          <Route path="/register" element={<SignupLayout/>}/>
          <Route path="/" element={<Layout/>}>
        </Route>
        </Routes>
      </Provider>
    </BrowserRouter>
    </>
  );
}

export default App;
