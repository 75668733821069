import React, { ChangeEvent, useEffect, useRef } from 'react';
import RemeberMe from '../../nestedComponents/rememberMe/RemeberMe';
import CustomButton from '../../nestedComponents/customButton/CustomButton';
import { SignupProps, SignupFormType } from '../../../../models/auth/auth';
import { Link } from 'react-router-dom';
import PasswordInput from '../../nestedComponents/passwordInput/PasswordInput';
import EmailInput from '../../nestedComponents/emailInput/EmailInput';
import { validateEmail, validateName, validatePassword } from '../../../../utils/validation';
import NameInput from '../../nestedComponents/nameInput/NameInput';
import { useAppDispatch, useAppSelector } from '../../../../RTK/hooks/hooks';
import { signupRequest } from '../../../../RTK/features/signupSlice/singupSlice';
import CitySelect from '../../nestedComponents/citySelect/CitySelect';
import { toast} from 'react-hot-toast';

function Login(props: SignupProps) {
  const { setPage, setEmail, email } = props;
  const [formData, setFormData] = React.useState<SignupFormType>({city_id: '', password: '', name: '' , remember_me: 'false' });
  const [disabled, setDisabled] = React.useState(true);
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [nameError, setNameError] = React.useState('');
  const [cityError, setCityError] = React.useState('');

  const {error, loading} = useAppSelector((state)=>state.signup)
  const dispatch = useAppDispatch()
  
  useEffect(()=>{
    if(error)
      toast.error(error, {duration: 3000});
  },[error])
  
  useEffect(() => {
    const emailValid = validateEmail(email);
    const passwordValid = validatePassword(formData.password);
    const nameValid = !(validateName(formData.name));
    const cityValid = formData.city_id ? true : false
    
    if(emailValid)
      setEmailError('');
    if(passwordValid)
      setPasswordError(false);
    if(nameValid)
      setNameError('')
    if(cityValid)
      setCityError('')

    setDisabled(!(emailValid && passwordValid && nameValid && cityValid));

  }, [email, formData.password, formData.name, formData.city_id]);


  async function handleSignup(){
    try {
      const resultAction = await dispatch(signupRequest({ ...formData, email, city_id: '1' })).unwrap();
      toast.success('Signup successful!', {duration: 3000});
      setPage(2)
    } catch {}
  };

  return (
    <div className='page'>
      <h3>Creating a new account</h3>
      <div className='form h-[435px]'>
        <div>
          <div className='textInputsWra'>
            <EmailInput
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />
            <NameInput
              name={formData.name}
              setName={(name) => {
                  setFormData(prev => ({...prev, name}))
              }}
              nameError={nameError}
              setNameError={setNameError}
            />
            <CitySelect handleChange={(e:{ name: string; value: string })=>{setFormData(pre =>{return {...pre, [e.name]:e.value}})}} formData={formData} error={cityError} setError={setCityError}/>
            <PasswordInput
              password={formData.password}
              setPassword={(password) => setFormData(prev => ({ ...prev, password }))}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
            />
          </div>
          <RemeberMe handleChange={(e) => setFormData(prev => ({ ...prev, remember_me: e.target.checked?'true':'false' }))} remember_me={formData.remember_me} />
        </div>
        <CustomButton
          content='Sing up'
          disabled={disabled || loading}
          action={() =>handleSignup()}
        />
      </div>
      <p className='DontHaveAccount'>
        Already have an account? <Link className='orangeUnderline' to='/login'>Log in</Link>
      </p>
    </div>
  );
}

export default Login;