import React from 'react'
import pencil from '../../../../assets/pics/pencil.svg'
import { NameInputProps } from '../../../../models/auth/auth'
import { validateName } from '../../../../utils/validation';

function NameInput(props: NameInputProps) {
    const { name, setName, nameError, setNameError } = props;
    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
        setNameError(validateName(event.target.value));
    } 
  return (
    <div>
        {nameError && <p className="errorText">{nameError}</p>}
        <div className="input">
            <img src={pencil} alt='lock' className='icon' />
            <input
                type="name"
                value={name}
                onBlur={handleBlur}
                onChange={(e) => setName(e.target.value)}
                name='name'
                id='name'
                placeholder="Name"
            />
        </div>
    </div>
  )
}

export default NameInput